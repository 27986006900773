<template>
<vContainer>
    <v-row>
      <s-toolbar label="Lista de Aprobaciones"></s-toolbar>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0 pr-0 pl-0">
        <v-data-table  
          dense 
          :items=itemsLevels 
          hide-default-footer 
          :headers=headers>

            <template v-slot:item.AtsStatusName="{item}">
                <v-chip x-small :color="item.AtsStatus==2?'success':item.AtsStatus==1?'':'error' ">
                  {{item.AtsStatus==3?'Desaprobado':item.AtsStatusName}}</v-chip>
            </template>

        </v-data-table>
      </v-col>
    </v-row>
</vContainer>
</template>

<script>
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
export default {
  name: "RequirementService",
  components: { },
  props: {
      DocEntry:null,TypeObject:null,
  },
  data: () => ({
     
      headers:[
        {text:'Cargo',value:'PstName'},
        {text:'Estado',value:'AtsStatusName',width:100},
        {text:'Fecha',value:'TypeLevelText',width:100},
        {text:'Observación',value:'AtsObservation'}
      ],
 itemsLevels:[],
  }),
  methods: {


  },
  watch: {
      DocEntry(){
        _sApprovalLevel.byDocument({DocEntry:this.DocEntry,TypeApproval :this.TypeObject},this.$fun.getUserID()).then((r)=>{
          this.itemsLevels=r.data;
          
        });}
      },
  created() {
      _sApprovalLevel.byDocument({DocEntry:this.DocEntry,TypeApproval :this.TypeObject},this.$fun.getUserID()).then((r)=>{
              this.itemsLevels=r.data;
              
      })
    
  },
  mounted() {},
};
</script>
