<template >
  <div>
    <alert-supplies
      v-if="hasSuppliesNotRecommended"
      :FltID="FltID"
      :listSupplies="listSupplies"
    ></alert-supplies>

    <alert-supplies :FltID="FltID"></alert-supplies>

    <v-card>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            close
            @close="close"
            color="blue"
            dark
            label="Recepción de materia prima"
          >
          </s-toolbar>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-top: 20px">
            <v-row>
              <v-col cols="3" class="s-col-form">
                <s-text
                  label="Cod. Prog Cosecha"
                  disabled
                  v-model="item.HvsID"
                ></s-text>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-date
                  :disabled="true"
                  label="Fecha recepción"
                  v-model="item.MarDateReception"
                ></s-date>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- desde aqui creacion de las tablas en el -->
        <v-row>
          <div class="col-md-7 col-sm-7" style="margin-top: 0px">
            <v-data-table
              item-key="GngID"
              dense
              :items="DataReceptionGang"
              :headers="headersGang"
              hide-default-footer
            >
              <template v-slot:item.MrgQuantityReal="props">
                <s-text v-model="props.item.MrgQuantityReal" number></s-text>
              </template>

              <template v-slot:item.MrgQuantityHarvester="props">
                <s-text
                  v-model="props.item.MrgQuantityHarvester"
                  number
                ></s-text>
              </template>

              <template v-slot:item.MrgName="props">
                <s-select-foreman
                  clearable
                  full
                  :value="props.item.FrmID"
                  v-model="props.item.FrmID"
                ></s-select-foreman>
              </template>

              <template v-slot:item.GngReload="props">
                <v-btn icon color="primary" x-small @click="refreshGng()">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>

          <!-- Inicio Vehicle -->
          <div class="col-md-5 col-sm-5">
            <v-row>
              <v-col cols="12" md="12" style="margin-top: 0px">
                <v-data-table
                  item-key="TptId"
                  dense
                  :items="DataReceptionTransport"
                  :headers="headersVehicle"
                  hide-default-footer
                >
                  <template v-slot:item.MrtEnrollment="props">
                    <s-select-transport
                      :value="props.item.MrtEnrollment"
                      full
                      v-model="props.item.MrtEnrollment"
                    >
                    </s-select-transport>
                  </template>
                  <template v-slot:item.MrtDriver="props">
                    <s-text v-model="props.item.MrtDriver"></s-text>
                  </template>

                  <template v-slot:item.TypeLoadName="props">
                    <s-select-definition
                      :def="1229"
                      v-model="props.item.TypeLoad"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <!-- </v-col> -->
          </div>
          <!-- Fin Vehicle -->
        </v-row>

        <!-- datos del fundo  -->
        <v-row>
          <div class="col-md-12 col-sm-12">
            <v-col cols="12" md="12" style="margin-left: 0px">
              <v-toolbar
                dense
                dark
                color="primary"
                style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6; margin-bottom: 10px;"
              >
                <v-toolbar-title style="height: 38px"
                  >Datos del fundo</v-toolbar-title
                >
              </v-toolbar>
              <v-row>
                <v-col cols="3" md="3">
                  <s-select-producer
                    clearable
                    :text="item.PrdCardName"
                    return-object
                    v-model="ObjProducer"
                    @input="inputProducer($event, item)"
                    label="Productor"
                  />

                  <s-text v-show="false" v-model="item.PrdCardName"></s-text>
                </v-col>
                <v-col cols="3" md="3">
                  <s-select
                    clearable
                    label="Fundo"
                    :items="itemsFarm"
                    item-text="FagName"
                    item-value="FagID"
                    @input="inputFarm($event, item)"
                    v-model="item.FagID"
                  />
                </v-col>

                <v-col cols="3" md="3">
                  <s-select
                    clearable
                    label="Lote"
                    item-text="FltName"
                    item-value="FltID"
                    :items="itemsLot"
                    v-model="item.FltID"
                  />
                </v-col>

                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    v-if="validateTypeService"
                    label="Empacadora"
                    :def="1226"
                    v-model="item.TypeBaler"
                  ></s-select-definition>
                </v-col>

                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    label="Destino"
                    :def="1175"
                    v-model="item.TypeDestiny"
                  ></s-select-definition>
                </v-col>

                <v-col cols="3" class="s-col-form">
                  <s-date
                    label="Fecha cosecha"
                    v-model="item.MarDateHarvestSchedule"
                  ></s-date>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    label="Origen"
                    :def="1174"
                    v-model="item.TypeOrigin"
                  ></s-select-definition>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-select-variety
                    :cultiveID="item.TypeCultive"
                    label="Variedad"
                    full
                    :text="item.VrtName"
                    :value="item.VrtID"
                    v-model="item.VrtID"
                  >
                  </s-select-variety>
                  <!-- <s-select-variety
                    label="Codigo de Variedad"
                    v-model="item.VrtID"
                  ></s-text> -->
                </v-col>

                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="Cant. Cunas cosechadas"
                    v-model="item.MarHarvestedCotsQuantity"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    label="Tipo servicio"
                    :def="1171"
                    @input="inputTypeService($event)"
                    v-model="item.TypeService"
                  ></s-select-definition>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    label="Tipo cultivo"
                    :def="1172"
                    v-model="item.TypeCrop"
                  ></s-select-definition>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    label="cultivo"
                    :def="1173"
                    v-model="item.TypeCultive"
                  ></s-select-definition>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-row>

        <v-row>
          <div class="col-md-12 col-sm-12">
            <v-col cols="12" md="12" style="margin-left: 0px">
              <v-toolbar
                dense
                dark
                color="primary"
                style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6; margin-bottom: 10px;"
              >
                <v-toolbar-title style="height: 38px"
                  >Datos de los documentos</v-toolbar-title
                >
              </v-toolbar>
              <v-row>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="N° Guia de cosecha"
                    v-model="item.MarNumberHarvestGuide"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="N° Guia de remisión"
                    v-model="item.MarNumberReferralGuide"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="N° Guia del conductor"
                    v-model="item.MarNumberFerralGuideDriver"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="N° Guia de Materiales"
                    v-model="item.MarNumberFerralGuideMaterials"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="Cod. Presinto sunshine"
                    v-model="item.MarSunshineSeal"
                  ></s-text>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="Cod. Presinto senasa"
                    v-model="item.MarSenasaSeal"
                  ></s-text>
                </v-col>
                <v-col cols="6" class="s-col-form">
                  <s-text
                    label="N° Guia conductor y materiales"
                    v-model="item.MarNumberFerralGuideDriverMaterials"
                  >
                  </s-text>
                </v-col>
                <v-col cols="12" class="s-col-form">
                  <s-textarea
                    label="Observación"
                    v-model="item.MarObservations"
                  ></s-textarea>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-row>

        <!-- boton para guardar datos -->
        <v-row justify="center" style="margin-top: 5%">
          <v-col cols="4" align-self="center">
            <v-btn @click="saveMate(item)" width="100%" color="primary"
              >GUARDAR</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import SDate from "../../../components/Utils/SDate.vue";
import SSelect from "../../../components/Utils/SSelect.vue";
import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
import _sMaterialReception from "../../../services/FreshProduction/MaterialReceptionService";

import _sReceptionSurveillance from "@/services/Technicalassistance/ReceptionSurveillanceService";

import _sMaterialReceptionGangService from "../../../services/FreshProduction/MaterialReceptionGangService";
import _sMaterialReceptionTransportService from "../../../services/FreshProduction/MaterialReceptionTransportService";
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import STextarea from "../../../components/Utils/sTextarea.vue";
import SSelectProducer from "../../../components/HarvestSchedule/SSelectProducer.vue";
import SSelectVariety from "../../../components/HarvestSchedule/SSelectVariety.vue";
import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";
import AlertSupplies from "../../../components/FreshProduction/FreshReception/AlertSupplies.vue";

export default {
  name: "MaterialReceptionEdit",
  components: {
    SDate,
    SSelect,
    SSelectDefinition,
    SSelectTransport,
    SSelectForeman,
    STextarea,
    SSelectProducer,
    SSelectVariety,
    AlertSupplies,
  },
  props: {
    item: null,
  },
  data: () => ({
    itemLocal: null,
    prt: 0,
    validateTypeService: false,
    FltID: 0,
    hasSuppliesNotRecommended: false,
    listSupplies: [],
    // guiNum: "",
    // AmountJabasWeigh: 0,
    // JabaWeight: 0,
    // AmountJabasReceived: 0,
    // GrossWeight: 0,
    DataReceptionGang: [],
    DataReceptionTransport: [],
    itemsLot: [],
    itemsFarm: [],
    isDisabled: true,
    MrgName: "",

    headersGang: [
      { text: "Reload", value: "GngReload" },
      { text: "Nro Cuadrilla", value: "MrgNumberGang" },
      { text: "Capataz", value: "MrgName", width: 250 },
      { text: "Cant. Personal.", value: "MrgQuantityHarvester" },
      { text: "Cant. Prog..", value: "MrgQuantityEstimate" },
      { text: "Cant. Cosechadas", value: "MrgQuantityReal" },
    ],

    headersVehicle: [
      { text: "Placa", value: "MrtEnrollment", width: 150 },
      { text: "Conductor", value: "MrtDriver", width: 150 },
      { text: "Tipo de Carga", value: "TypeLoadName", width: 100 },
      /* { text: "Tipo Vehiculo", value: "TptVehicle" },
      { text: "Chofer", value: "TptDriver", width: 200}, */
    ],

    filters: {},

    config: {
      model: {},
      // service: _sMaterialReceptionGangService, // llama al servicio para el sp prfMaterialReceptionGang_s
      headers: [],
    },

    ObjProducer: {},
  }),

  computed: {},

  methods: {
    inputTypeService(elemento) {
      if (elemento == 1) {
        this.validateTypeService = true;
        this.item.TypeBaler = 0;
        // this.filters.MarID = item.MarID;
      } else {
        this.validateTypeService = false;
      }
    },
    refreshGng() {
      _sReceptionSurveillance
        .GetGang({ HvsID: this.selected.HvsID }, this.$fun.getUserID())
        .then((r) => {
          this.itemsGang = r.data;
        });
    },
    inputProducer(objPro, item) {
      if (objPro == null) {
        this.itemsFarm = [];
        item.FagID = null;
        return;
      }

      _sReceptionSurveillance
        .GetAgriculturalFarm(objPro.PrdID, "", this.$fun.getUserID())
        .then((r) => {
          this.itemsFarm = r.data;
          if (this.itemsFarm.length > 0) item.FagID = this.itemsFarm[0].FagID;
        });
    },

    inputFarm(FagID, item) {
      if (FagID == null) {
        this.itemsLot = [];
        return;
      }
      _sReceptionSurveillance
        .GetLot(FagID, 0, "", this.$fun.getUserID())
        .then((r) => {
          this.itemsLot = r.data;
          if (this.itemsLot.length > 0) item.FltID = this.itemsLot[0].FltID;
        });

      let resulFam = this.itemsFarm.filter((x) => x.FagID == FagID);
      this.FagAddress = resulFam.FagAddress;
      this.SzoDescription = resulFam.SzoDescription;
      this.ZonDescription = resulFam.ZonDescription;
    },

    close() {
      this.$emit("close");
    },

    initializeGang() {
      _sMaterialReceptionGangService
        .getGang(this.$fun.getUserID(), this.item.HvsID)
        .then((r) => {
          // alert("primer lanxamiento");
          this.DataReceptionGang = r.data;
          console.log("ejecutando DataReceptionGang ", this.DataReceptionGang);
          //this.itemsGang = r.data;
        });
    },

    initializeTransport() {
      _sMaterialReceptionTransportService
        .getTransport(this.$fun.getUserID(), this.item.HvsID)
        .then((r) => {
          // alert("primer lanxamiento");
          this.DataReceptionTransport = r.data;
          console.log(
            "ejecutando DataReceptionTransport ",
            this.DataReceptionTransport
          );
          //this.itemsGang = r.data;
        });
    },

    saveMate(item) {
      item.MarAddHeavy = 1;
      this.item.DataReceptionGang = this.DataReceptionGang;
      this.item.DataReceptionTransport = this.DataReceptionTransport;
      // this.item.MarID = 0;
      console.log(item.FltID);

      this.$fun.alert("Esta seguro de guardar ?", "question").then((val) => {
        if (val.value) {
          console.log("registros generales", this.item);
          _sMaterialReception
            .save(this.item, this.$fun.getUserID())
            .then(() => {
              this.$fun.alert("Agregado correctamente", "success");
              this.$emit("close");
            });
        }
      });
    },

    disabledComponents() {
      alert("dddd");
      this.isDisabled = false;
    },
  },
  created() {
    this.initializeGang();
    this.initializeTransport();

    console.log(this.DataReceptionGang);
    console.log("datos generales ", this.item);
    this.FltID = this.item.FltID;
    this.isDisabled = true;

    //codigo carlos
      /* this.FltID = this.item.FltID;
      
      console.log('data received ', this.item);
      _sAlertHistoryService.suppliesnotrecommended(this.FltID, '11/17/2021', this.$fun.getUserID())
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data;
          if (data.length > 0) {
            this.hasSuppliesNotRecommended = true;
            data.forEach((element) => {
              this.listSupplies.push(element);
            });
          }
        }
      }) */
      //end carlos

  },
};
</script>