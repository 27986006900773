<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete
      class="text"
      itemvalue="VehPlate"
      :disabled="disabled"
      :value="value"
      id="VehID" itemtext="VehPlate"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="clearable"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "../../services/Technicalassistance/HarvestSchedule.js";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    
    
    value: null,

    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },

    returnObject: {
      type: Boolean,
      default: false,
    },

    /* typeMaterial: {
      type: Number,
      default: 0,
    }, */

    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
    /* typeVehicle(){
        this.config.params.AdvID=this.AdvID
    }, */
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    //alert(this.text);
    this.config = {
      url: _sService.getUrlHarvestListTransport(),
      title: this.label,
      params: { search: "", requestID: this.$fun.getUserID() , AdvID:0 },
    };
  },
};
</script>
