<template>
  <div>
    <!-- LISTADO DE PROGRAMACIONES DE  RECEPCION DE MATERIA PRIMA  -->
    <v-row>
      <v-col style="padding-bottom: 0px">
        <s-crud
          :title="label"          
          edit
          @edit="EditProgMaterial()"
          remove
          @rowSelected="rowSelected($event)"
          addNoFunction
          ref="grid"
          :config="config"
          :filter="filterRequirement"
          search-input=""
          excel
          pdf
          @pdf="pdf()"
        >
          <template v-slot:filter>
            <v-container>
              <v-container>
                <v-row justify="center">
                  <v-col lg="2" cols="2" >
                    <v-btn @click="WeighMaterial()" color="info">Pesar</v-btn>
                  </v-col>
                  <v-col lg="2" cols="2" class="s-col-form">
                    <s-select-definition
                      clearable
                      label="Estados"
                      v-model="filterRequirement.ReqStatus"
                      :def="1186"
                     
                  /></v-col>
                  <!-- <v-col lg="2" cols="2" class="s-col-form"
                    ><s-select-campaign
                      label="Campaña"
                      v-model="filterRequirement.CmpID"
                    />
                  </v-col> -->
                  <v-col lg="2" cols="2" class="s-col-form">
                    <s-date
                      v-model="filterRequirement.BeginDate"
                      label="Fecha Inicio"
                    />
                  </v-col>
                  <v-col lg="2" cols="2" class="s-col-form">
                    <s-date
                      v-model="filterRequirement.EndDate"
                      label="Fecha Fin"
                  /></v-col>
                </v-row>
              </v-container>
            </v-container>
          </template>
          <template v-slot:MarAddHeavy="{ row }">
            <v-chip
              x-small success
              :color=" row.MarAddHeavy != 1 ? 'error' :  'success'">
              {{ row.MarAddHeavy== 1 ? "recepcionado" : "No - recep"   }}
            </v-chip>
          </template>

          <!-- <template v-slot:addheavy="{ row }">
            <v-btn class="mx-2"
            x-small @click="saveMate(row)">
              <v-icon x-small>ff</v-icon>
            </v-btn>
          </template> -->

        </s-crud>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogWeigh" v-if="dialogWeigh" lg width="90%">
      <!-- <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="2" /> -->
      <v-card class="m-0 p-0">
        <s-toolbar label='Pesado' dark color="primary" close @close='dialogWeigh=false;' class="mb-6"></s-toolbar>
       <WeighedByBatch :DocEntry="DocEntry" :TypeObject="2"></WeighedByBatch>

      </v-card>
     
    </v-dialog>

     <v-dialog v-model="dialogEdit" v-if="dialogEdit" lg width="90%">
      <!-- <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="2" /> -->
       <MaterialReceptionEdit :item="itemLocal"  @close="close" ></MaterialReceptionEdit>
    </v-dialog> 

    <!-- <v-dialog v-model="dialogOrder" v-if="dialogOrder" width="100%">
      <OrderPurchaseEdit  @download="pdfOrder($event)" :value="OrdID" @onClose="dialogOrder = false" @load="dialogOrder = true" :viewLoad="false" />
    </v-dialog> -->
  </div>
</template>

<script>
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import WeighedByBatch from "@/views/FreshProduction/RawMaterialReception/WeighedByBatchG";
import MaterialReceptionEdit from "@/views/FreshProduction/RawMaterialReception/MaterialReceptionEdit";

import _sMaterialReception from "../../../services/FreshProduction/MaterialReceptionService";

// importado para modal ver orden
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";


export default {
  name: "MaterialReception",
  components: { ApprovalLevelsDocument, OrderPurchaseEdit,WeighedByBatch,MaterialReceptionEdit },
  props: {
    TypeRequirement: { default: 1, type: Number },
    label: { default: "Requerimiento de Compra", type: String },
  },
  data: () => ({
    OrdID: null,
    dialogOrder: false,
    dialogWeigh: false,
    dialogEdit:false,
    DocEntry: null,
    RequirementEdit: {},
    TypeCost: 0,
    centercost: 0,
    bslID: 0,
    dialog: false,
    filterRequirement: {
      BeginDate:"",
      EndDate:"",
      // TypeState:1,
      CmpID:"", // campaña
      
    },
    selected: [],
    itemLocal: {},
    config: {
      model: {
        addheavy:"",
        RcsID: "ID",
        MarDateReception:"date",
        MarAddHeavy:""
        // ReqDocumentNumber: "ID",
        // ReqDate: "date",
        // SecUpdate: "datetime",
        // RqStatusName: "",
      },
      service: _sMaterialReception,
      headers: [
        
        { text: "Estado", value: "MarAddHeavy", sortable: false },
        // { text: "ID", value: "cHarvestNumberProgramming", sortable: false, width: 40 },
        { text: "Lote", value: "FltName", sortable: false },

        { text: "F. Recep. Vigilancia", value: "MarDateReception", sortable: false, width: 100},
        { text: "N° Guia Cosecha", value: "MarNumberHarvestGuide", sortable: false ,width: 100},
        { text: "N° Guia Remisión", value: "MarNumberReferralGuide", sortable: false, width: 100 },
        { text: "N° Guia del conductor", value: "MarNumberFerralGuideDriver", sortable: false, width: 100 },
        { text: "N° Guia de Materiales", value: "MarNumberFerralGuideMaterials", sortable: false, width: 100 },
        // { text: "Cant. cunas", value: "MarHarvestedCotsQuantity", sortable: false, width: 100 },
        { text: "Cod. sunshine", value: "MarSunshineSeal", sortable: false, width: 110 },
        { text: "Cod senasa", value: "MarSenasaSeal", sortable: false, width: 120 },
        { text: "Id proc. planta", value: "TypeProcessingPlant", sortable: false, width: 50, align: "center"},
        { text: "empacadora", value: "TypeBaler", sortable: false, width: 140 },
       
      ],
    },
  }),
  methods: {

    close() {
      this.dialogEdit=false
    },
    
    // removed(items) {
    //   this.$fun.alert("Seguro de Anular?", "question").then((r) => {
    //     if (r.value) {
    //       items.map((item) => {
    //         item.DerStatus = 9;
    //         return item;
    //       });
    //       _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
    //         this.$fun.alert("Anulado Correctamente", "success");
    //         this.rowSelected(this.selected);
    //         this.itemsDetailSelect = [];
    //       });
    //     }
    //   });
    // },


    // dblClick(e, { item }) {
    //   if (item.OrdID != null) {
    //     console.log(e);
    //     this.dialogApproval = true;
    //     this.DocEntry = item.OrdID;
    //   }
    // },

    dobleClick(item) {
      this.$emit("dblClick", item);
    },

    EditProgMaterial(item) {
      this.dialogEdit=true
    },

    WeighMaterial () {
      this.dialogWeigh=true
    },

    orderView(item) {
      this.OrdID = item;
      this.dialogOrder = true;
    },

    rowSelected(item) {
      // if(item.length>0)
      this.itemLocal=Object.assign(item[0],{})
      // this.selected = item;
      // if (item != null) {
      //   if (item.length > 0) {
      //     if (item[0].ReqID != null)
      //       _sRequirementDetail
      //         .detail({ ReqID: item[0].ReqID }, this.$fun.getUserID())
      //         .then((r) => {
      //           this.itemsDetail = r.data;
      //         });
      //   }
      // }
    },
   
    pdfOrder(item) {

      _sOrderPurchase.PDF(item, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          "Orden_compra"
        );
      });
    },

    pdf() {
      var obj = {
        ReqID: this.selected[0].ReqID,
      };

      _sRequirement.pdf(this.selected[0], this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          this.$fun.getUserID()
        );
      });
    },
  },
  watch: {
    dialog() {
      this.$refs.grid.refresh();
    },
  },
  created() {
    // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
    if (!this.$fun.isAdmin())
      this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;

    this.filterRequirement.TypeRequirement = this.TypeRequirement;
    if (this.TypeRequirement == 1) {
      this.headersDetail.splice(
        1,
        0,
        { text: "Und", value: "UnitMeasurement", sortable: false },
        { text: "Cant", value: "DerQuantity", sortable: false }
      );
    } else if (this.TypeRequirement == 2) {
      this.headersDetail.splice(
        1,
        0,
        { text: "Und", value: "UnitMeasurement", sortable: false },
        { text: "Cant", value: "DerQuantity", sortable: false }
      );
    }
  },
  mounted() {},
};
</script>
