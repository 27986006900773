<template>
  <div>
    <!-- <weighed-by-batch label="Pesado por lote"></weighed-by-batch> -->
  </div>
</template>
<script>
/* import weighedByBatch from "./WeighedByBatch.vue"; */
export default {
  /* name: "MaterialReceptionG", */
  /* components: { weighedByBatch }, */
  props: {},
  data: () => ({}),
};
</script>