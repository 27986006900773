<template>
  <!-- receccion de materia prima -->
  <div>
   <material-reception label="Recepción de materia prima" :TypeRequirement=1></material-reception> 
  </div>
</template>

<script>

import materialReception from "./MaterialReception.vue";


export default {
  name: "MaterialReceptionG",
  components: { materialReception },
  props: {},
  data: () => ({}),
};
</script>