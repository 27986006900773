<template >
  <div v-if="listSupplies.length > 0">
    <v-alert
      v-model="alert"
      border="right"
      color="orange"
      dark
      dense
      dismissible
      shaped
      close-icon="fas fa-times-circle"
      style="border-radius: 10px 10px 10px 10px; overflow: auto; z-index: 9999999; position: fixed; width: 100%; right: 0px; top: 5%;"
    >
    <!-- style="border-radius: 10px 4px; display: block; position: fixed; z-index: 9999999; overflow: auto; left: 50%; bottom: 50px; transform: translate(-40%, -30%); margin: 0 auto; width: 60%" -->
      <span>{{ message }}</span>

      <v-list-item v-for="item in listSupplies" :key="item.LsID">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon x-small>fas fa-box</v-icon>
            {{ item.LsDescription }}
          </v-list-item-title>
          <!-- <v-list-item-title>
						<v-icon x-small>fas fa-box</v-icon>
						Producto 2
					</v-list-item-title>
					<v-list-item-title>
						<v-icon x-small>fas fa-box</v-icon>
						Producto 3
					</v-list-item-title> -->
        </v-list-item-content>
      </v-list-item>
    </v-alert>
  </div>
</template>

<script>
import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";

export default {
  props: {
   /*  listSupplies: {
      type: Array,
      default: [],
    }, */
    FltID: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      listSupplies: [],
      alert: true,
      message: "Productos aplicados no permitidos",
    };
  },

  created() {
     _sAlertHistoryService.suppliesnotrecommended(this.FltID, '11/17/2021', this.$fun.getUserID())
      .then((resp) => {
        if(resp.status == 200){
          let data = resp.data;
          if(data.length > 0){
            this.hasSuppliesNotRecommended = true;
            data.forEach((element) => {
              this.listSupplies.push(element);
            });

            if (this.listSupplies.length > 0) {
              let suppliesAr = [];
              this.listSupplies.forEach((element) => {
                suppliesAr.push(element.LsDescription);
              });
              let list = suppliesAr.join("-");
              let messa = this.message + " " + list;

              let alh = {
                AlhMessage: messa,
                SecStatus: 1,
                AlhStatus: 1,
                FltID: this.FltID,
                AlhDateCreate: new Date(),
                SecCreate: new Date(),
                SecUpdate: new Date(),
                UsrCreateID: this.$fun.getUserID(),
                UsrUpdateID: this.$fun.getUserID(),
              };

              _sAlertHistoryService.save(alh, this.$fun.getUserID()).then((resp) => {
                if (resp.status == 200) {
                }
              });
            }
            
          }
        }
      })


    /* if (this.listSupplies.length > 0) {
      let suppliesAr = [];
      this.listSupplies.forEach((element) => {
        suppliesAr.push(element.LsDescription);
      });
      let list = suppliesAr.join("-");
      let messa = this.message + " " + list;

      let alh = {
        AlhMessage: messa,
        SecStatus: 1,
        AlhStatus: 1,
        FltID: this.FltID,
        AlhDateCreate: new Date(),
        SecCreate: new Date(),
        SecUpdate: new Date(),
        UsrCreateID: this.$fun.getUserID(),
        UsrUpdateID: this.$fun.getUserID(),
      };

      _sAlertHistoryService.save(alh, this.$fun.getUserID()).then((resp) => {
        if (resp.status == 200) {
        }
      });
    } */
  },
};
</script>
