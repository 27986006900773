import Service from "../Service";

const resource = "materialReceptionGang/"

export default {

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
    });
  },   

  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
        params: { requestID: requestID },
    });
  },

  getGang(requestID, RcsID) {
    // alert("service", RcsID)
    return Service.post(resource + "getGang", "", {
        params: { requestID: requestID, RcsID: RcsID },
    });
  },


};