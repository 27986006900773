import Service from "../Service";

const resource = "materialReception/"

export default {

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
    });
  },   

  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
        params: { requestID: requestID },
    });
  },

  byCreateLotProduction(parameters, requestID) {
    return Service.post(resource + "list_by_create_lotproduction", parameters, {
        params: { requestID: requestID },
    });
  }, 

};